import { data } from 'jquery';
import React, { Component } from 'react';
import axios from "axios";
class Activities extends Component {
    constructor(props) {
        super(props);
        this.state={
          filteredEvents: [],
          ActivitieDetails:[],
            currentYear:new Date().getFullYear()
        }
      }//End Constructor
    getDateDay =(date)=>{
        let inputDate = new Date(date);

// Options for formatting the date
let options = { day: '2-digit', month: 'short' };

// Format the date
let formattedDate = inputDate.toLocaleDateString('en-US', options);

// Get the day of the week
let dayOfWeek = inputDate.toLocaleDateString('en-US', { weekday: 'long' });

// Combine the formatted date and day of the week
 
let output = `${formattedDate}, ${dayOfWeek}`;

return output
    }
    fetchItems = () => {
      
      axios.get('https://trust.sharavisual.com/api/getActivities.php')
          .then(response => {
              this.setState({ ActivitieDetails: response.data.data },()=>{
                this.filterEvents()
              });
          })
          .catch(error => console.error("There was an error fetching the items!", error));
  };
componentDidMount(){
  this.fetchItems();
}

filterEvents = () => {
  const { ActivitieDetails } = this.state;
  const currentYear = new Date().getFullYear();

  const groupedEvents = ActivitieDetails.reduce((acc, event) => {
    const eventYear = new Date(event.date).getFullYear();
    if (!acc[eventYear]) {
      acc[eventYear] = [];
    }
    acc[eventYear].push(event);
    return acc;
  }, {});

  const sortedYears = Object.keys(groupedEvents)
    .map(Number)
    .filter((year) => year >= currentYear)
    .sort((a, b) => a - b);

  const filteredEvents = sortedYears.map((year) => ({
    year,
    events: groupedEvents[year]
  }));

  this.setState({ filteredEvents:filteredEvents });
};
  render() {
      

    return (
      <div className="container">
        <div className="row py-5">
        {this.state.filteredEvents.length >0 && this.state.filteredEvents.map((yearGroup) => (
          <div key={yearGroup.year}>
            <h2 style={{color:"#db7527"}}>Activitie {yearGroup.year}</h2>
            <div className="col-lg-10 border border-top-0 border-start-0 border-end-0 wow fadeInUp " data-wow-delay="0.1s">
              {yearGroup.events.map((event) => (
                <p key={event.id}>
                  <strong>{event.activitieName}</strong> - {event.date} at{" "}
                  {event.place} ({event.time})
                </p>
              ))}
            </div>
          </div>
        ))}
          
        </div>
      </div>
    );
  
  }
}
export default Activities;
