import React from 'react';
import DataTable from 'react-data-table-component';
export default class GetInvolvedTable extends React.Component {
  constructor() {
    super();
    this.state = {GetInvolvedList:[]};
  }
  getInvolvedList=()=>{
    fetch('https://trust.sharavisual.com/api/getInvolved.php')
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.status) {
        this.setState({ GetInvolvedList: data.data });
        console.log('Fetched data:', data);
      } else {
        console.error('Error from API:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
   
    } 
   
    componentDidMount() {
      
      this.getInvolvedList();
      
    }
  render() {
    const columns = [
        {
          name: 'sectionName',
          selector: (row) => row.sectionName,
          sortable: true,
        },
        {
          name: 'Category',
          selector: (row) => row.VolunteerCategory,
          sortable: true,
        },
        {
          name: 'Name',
          selector: (row) => row.name,
          sortable: true,
        },
        {
          name: 'Address 1',
          selector: (row) => row.address1,
          sortable: true,
        },
        {
            name: 'Address 2',
            selector: (row) => row.address2,
            sortable: true,
          },
          {
            name: 'City',
            selector: (row) => row.city,
            sortable: true,
          },

          {
            name: 'state',
            selector: (row) => row.state,
            sortable: true,
          },
          {
            name: 'Pin',
            selector: (row) => row.zipcode,
            sortable: true,
          },
          {
            name: 'PAN No',
            selector: (row) => row.PANno,
            sortable: true,
          },

          {
            name: 'Amount',
            selector: (row) => row.amount,
            sortable: true,
          },
      ];
    return (
         <>
         <DataTable
          columns={columns}
          data={this.state.GetInvolvedList}
          pagination
        />
         </>
    );
  }
}