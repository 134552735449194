import React, { Component } from 'react';
import QR from '../assets/img/image 1.png';
import DonationAccountInfo from "../component/donationAccount";
import DonationForm from './donationForm';
import InvolvedFrom from './involvedForm';
class Involved extends Component {
    constructor() {
        super();
        this.state = {
            getInvolvedInfo:{},
            getInvlovedContent:[],
                      leftSectionAccount:[]
                     };
      }
    getDonationAccountInfo=()=>{
        fetch('https://trust.sharavisual.com/data/athirudram.json')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          this.setState({leftSectionAccount:data.leftSectionAccount,
                         })
          console.log('Fetched data:', data);
          // You can now use the data in your component state
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        }); 
    }
    getInvlovedContent=()=>{
        fetch('https://trust.sharavisual.com/data/getInvolved.json')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          this.setState({
            getInvolvedInfo:data,
            getInvlovedContent:data.getInvolvedContent,
                         })
          console.log('Fetched data:', data);
          // You can now use the data in your component state
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        }); 
    }
    componentDidMount() {
        
        this.getDonationAccountInfo();
        this.getInvlovedContent();
      }   
    render() {
        
        return (

            <div className="container">
                <div className="row mt-4 ">
                    <h2 style={{ color: "#DB7527" }}>{this.state.getInvolvedInfo.heading!=undefined && this.state.getInvolvedInfo.heading}</h2>
                    {this.state.getInvolvedInfo.subHeading!=undefined && this.state.getInvolvedInfo.subHeading !="" && <h4>{this.state.getInvolvedInfo!=undefined && this.state.getInvolvedInfo.subHeading}</h4>}

                    <div className="col-lg-12 mt-3 wow fadeInUp" data-wow-delay="0.1s">
                            {this.state.getInvlovedContent.length>0 && this.state.getInvlovedContent.map((item,index)=>{
                                return(
                                    <>
                                    {item.heading != "" && <h5>{item.heading}How can you participate and support?</h5>}
                                    {item.subTitle != "" && <h6>{item.subTitle}</h6>}
                                    <div dangerouslySetInnerHTML={{__html: item.description}}></div>
                                    </>
                                )
                            })}
                            
                        
                    </div>
                </div>
                <div className='row mb-5 d-flex justify-content-center'>

                    <div class="col-md-10 grid-margin stretch-card mt-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="card">
                            <div class="card-body">
                                <InvolvedFrom {...this.props}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className="col-lg-12 mt-3 wow fadeInUp" data-wow-delay="0.1s">
                        <p><strong>{this.state.getInvolvedInfo.note!=undefined && this.state.getInvolvedInfo.note}</strong></p>
                    </div>
                </div>
                <div className='row mb-5 g-5'>
                    <div className="col-12 col-lg-4 mt-3 wow fadeInUp text-center" data-wow-delay="0.1s">
                    <DonationAccountInfo {...this.state}/>                        
                    </div>
                    <div class="col-12 col-md-8 col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="card p-4">      
                        <DonationForm/>
                    </div>
                </div>
  

                </div>
            </div>



        );
    }
}

export default Involved;
