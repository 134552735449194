import React from 'react';
import logo from '../assets/img/logo.png';
export default class Footer extends React.Component {
  constructor() {
    super();
    this.state = {footerMenu:[]};
  }
  getFooterMenu=()=>{
    fetch('https://trust.sharavisual.com/data/footerMenu.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.setState({footerMenu:data})
        console.log('Fetched data:', data);
        // You can now use the data in your component state
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      }); 
  }
  componentDidMount(){
    this.getFooterMenu()
  }
  render() {
    return <>
    <div
      class="container-fluid bg-dark footer pt-2 p-0 wow fadeIn"
      data-wow-delay="0.1s">
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-4 col-md-4 text-center">
            <img src={logo} style={{width:"150px"}}/>           
            
          <h5 class="text-white mb-4"> Sree Ramakrishna <br/>Bhajana Sabha Trust (Regd.) <br/>
          <small className="fs-7 text-white me-2">Uplifting Spirits, Serving Society.</small>
          </h5>
          
          </div>
          <div class="col-lg-5 col-md-5 text-white pt-5">
            {/* <h4 class="text-light mb-4">Address</h4> */}
            <p className='text-center'>
              “Shiva Krupa” # 15, Damodara Mudaliyar Street, <br/>Halasuru, Bengaluru-560008.
            </p>
            <p className='text-center'>Mob: 988011024 / 8618994721 / 9900793536 /  9854812545</p>
            <p className='text-center'>Email: srkbst1951@gmail.com</p>
          </div>

          <div class="col-lg-3 col-md-5">
            {/* <h4 class="text-light mb-4">Quick Links</h4> */}
            {this.state.footerMenu.length>0 && this.state.footerMenu.map((item,index)=>{
              
              return(
                <a class="btn btn-link w-100 text-start text-white text-decoration-none" href={item.link}>{item.name}</a>
               
              )
            })}
            
           
          </div>
         
          
        </div>
      </div>
      <div class="container-fluid copyright bg-white ">
        <div class="container">
          <div class="row">
            <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a href="#">2024 by Ramakrishna Bhajana Sabha Trust</a>, All Right Reserved.
            </div>
            
          </div>
        </div>
      </div>
    </div>
    </>;
  }
}