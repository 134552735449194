import React from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import '../../assets/admin/css/login.css';
import '../../assets/admin/css/admin.scss';
import GetInvolvedTable from '../admin/getInvolvedTable';
import DonationTable from '../admin/donationTable';

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {GetInvolvedList:[],DonationList:[]};
  }
  getInvolvedList=()=>{
  fetch('https://trust.sharavisual.com/api/getInvolved.php')
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    if (data.status) {
      this.setState({ GetInvolvedList: data.data });
      console.log('Fetched data:', data);
    } else {
      console.error('Error from API:', data.message);
    }
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
 
  } 
 
  getDonationList=()=>{
 fetch('https://trust.sharavisual.com/api/getdonation.php')
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.status) {
        this.setState({ GetInvolvedList: data.data });
        console.log('Fetched data:', data);
      } else {
        console.error('Error from API:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
   
  } 


  componentDidMount() {
    
    this.getInvolvedList();
    this.getDonationList();
  }
  render() {
    return (
         <>
   <div class="main-panel">
    <div class="content-wrapper">
    <div class="row g-5">
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">People Involved</h4>
              <GetInvolvedTable {...this.state}/>
          </div>
        </div>
      </div>
      </div>
      <div class="row">
      <div class="col-lg-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Donated People</h4>
            <DonationTable {...this.state}/>
           
          </div>
        </div>
      
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Activities</h4>
         
        </div>
      </div>
    </div>
    </div>
    </div>

    </div>
         </>
    );
  }
}
export default withRouter(Dashboard);