import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";
class DonationForm extends Component {
  constructor(props){
    super(props);
    this.state={
        ColumnClassName:'',
        name:'',
        age: '',
        address:'',      
        phone:"",
        email:'',
        zipcode: '',
        PANno:'',
        trnxNo:"",
        isdisableDonationBtn:true,
        isvilated:false
    }
  }
      // Handle input change
      handleInputChange = (e) => {
        
        const { name, value } = e.target;
        this.setState({ [name]: value },()=>{
          if(this.state.isvilated){
            this.donationValidation();
         }
          
        });
    };  

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.donationValidation()){
                   
            const { name,age,address,phone,email, zipcode, PANno, trnxNo } = this.state;
    
            // Create a FormData object to hold the data as form-data (multipart/form-data)
            const formData = new FormData();
          
            formData.append('name', name);
            formData.append('age', age);
            formData.append('address', address);           
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('zipcode', zipcode);
            formData.append('PANno', PANno);
            formData.append('trnxNo', trnxNo);
        
            axios.post('https://trust.sharavisual.com/api/donation.php', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then((response) => {
                console.log(response.data);
                
                if (response.status) {
                     this.setState({
                        name:'',
                        age: '',
                        address:'',                       
                        phone:'',
                        email:'',
                        zipcode: '',
                        PANno:'',
                        trnxNo:'',
                        isdisableDonationBtn:true,isvilated:false})
                    //alert(response.message);
                } else {
                    alert('Failed to add contact: ' + response.message);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

        }
       
    };
    
    donationValidation=()=>{
        let flag =true;
              
        if(this.state.name==''){
            flag=false;
        }
        if(this.state.age==''){
            flag=false;
        }
        if(this.state.address==''){
            flag=false;
        } 
        if(this.state.phone==''){
          flag=false;
        } 
        if(this.state.email==''){
        flag=false;
        }       
        if(this.state.zipcode==''){
            flag=false;
        }
        if(this.state.PANno==''){
          flag=false;
      }
      if(this.state.trnxNo==''){
          flag=false
      }
       
        this.setState({isdisableDonationBtn: (flag == true ? true : false),isvilated:true})
        return flag
     }   


  getPageLoadOnLeftPanel=()=>{
     this.setState({ColumnClassName:  this.props.location.pathname == '/Athirudram' ? 'col-12 col-lg-12' :'col-6 col-lg-6'})
   
  }
  componentDidMount(){
    this.getPageLoadOnLeftPanel()
  }
  render() {
   

    return (
        <>
         <form className={ this.props.location.pathname == '/Athirudram' ? 'row border' : 'row'}>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="name" class="form-label">Name: <span className='text-danger'>*</span></label>
          <input type="text" class="form-control" id="name" name="name" value={this.state.name} onChange={this.handleInputChange} required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="age" class="form-label">Age: <span className='text-danger'>*</span></label>
          <input type="number" class="form-control" id="age" name="age" value={this.state.age} onChange={this.handleInputChange} required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="address" class="form-label">Address: <span className='text-danger'>*</span></label>
          <input type="text" class="form-control" id="address" name="address" value={this.state.address} onChange={this.handleInputChange} required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="zipcode" class="form-label">Pincode: <span className='text-danger'>*</span></label>
          <input type="text" class="form-control" id="zipcode" name="zipcode" value={this.state.zipcode} onChange={this.handleInputChange} required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="phone" class="form-label">Ph: <span className='text-danger'>*</span></label>
          <input type="tel" class="form-control" id="phone" name="phone" value={this.state.phone} onChange={this.handleInputChange} required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="email" class="form-label">Email: <span className='text-danger'>*</span></label>
          <input type="email" class="form-control" id="email" name="email" value={this.state.email} onChange={this.handleInputChange} required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="pan" class="form-label">Pan No.: <span className='text-danger'>*</span></label>
          <input type="text" class="form-control" id="pan" name="PANno" value={this.state.PANno} onChange={this.handleInputChange} required/>
        </div>
        <div class={`mb-3 ${this.state.ColumnClassName}`}>
          <label for="pan" class="form-label">Transtration No.: <span className='text-danger'>*</span></label>
          <input type="text" class="form-control" id="pan" name="trnxNo" value={this.state.trnxNo} onChange={this.handleInputChange} required/>
        </div>
        {!this.state.isdisableDonationBtn && <div className='col-12 col-lg-12'>
        <span className='float-start text-start text-danger'>* All are Required</span>
        </div>}

        <div class="d-flex justify-content-center mt-2 mb-2">        
           <button type="submit" class="btn btn-success me-2" onClick={this.handleSubmit} 
             disabled={(!this.state.isdisableDonationBtn && !this.state.isvilated)}>Submit</button>
        </div>

      </form>
        </>
    );
  }
}

export default withRouter(DonationForm)
