import React from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import '../../assets/admin/css/login.css';
import '../../assets/admin/css/admin.scss';
import GetInvolvedTable from '../admin/getInvolvedTable';
import DonationTable from '../admin/donationTable';
import Sidebar from './sidebar';
import AddActivitie from '../admin/activitiesEntry';
import GetActivitiesTable from '../admin/getActivitiesTable';

class AdminLayout extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
         <>
   <div class="main-panel">
    <div class="content-wrapper">
        <div className='row'>
        <div className='col-12 col-lg-2'>
          <Sidebar {...this.state} {...this.props}/>
       </div>

       <div className="col-12 col-lg-10">
         { this.props.location.pathname !== '/AddActivitie' && 
           <div class="card">
           <div class="card-body">
           {this.props.location.pathname === '/GetInvolvedList' && 
              <>
              <h4 class="card-title">Get Involved List</h4>
              <GetInvolvedTable/>
              </>
           }
           {this.props.location.pathname === '/DonationList' && 
              <>
              <h4 class="card-title">Donation List</h4>
              <DonationTable/>
              </>
           }
           {this.props.location.pathname === '/ActivitieList' && 
              <>
              <h4 class="card-title">ActivitieList</h4>
              <GetActivitiesTable/>
              </>
           }
          </div>
           </div>  
         }

         {this.props.location.pathname === '/AddActivitie' && 
              <>
              
              <AddActivitie/>
              </>
        }
       </div>
        </div>
     
    </div>
    </div>
         </>
    );
  }
}
export default withRouter(AdminLayout);