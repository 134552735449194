import React, { Component } from 'react';
class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: null,
      selectedImage: null,
      isLightboxOpen: false,
      images: props.images || [
        { id: 1, src: "https://trust.sharavisual.com/assets/gallery/gallery1.jpg", title: "Image 1", year: 2021, name: "Kalotsav" },
        { id: 2, src: "https://trust.sharavisual.com/assets/gallery/gallery2.jpg", title: "Image 2", year: 2020, name: "Kalotsav" },
        { id: 3, src: "https://trust.sharavisual.com/assets/gallery/gallery3.jpg", title: "Image 3", year: 2021, name: "Kalotsav" },
        { id: 4, src: "https://trust.sharavisual.com/assets/gallery/gallery4.jpg", title: "Image 4", year: 2019, name: "Kalotsav" },
        { id: 5, src: "https://trust.sharavisual.com/assets/gallery/gallery5.jpg", title: "Image 5", year: 2020, name: "Kalotsav" },
      ],
    };
  }

  // Toggle lightbox visibility
  openLightbox = (image) => {
    this.setState({ selectedImage: image, isLightboxOpen: true });
  };

  closeLightbox = () => {
    this.setState({ selectedImage: null, isLightboxOpen: false });
  };

  // Filter images by name and year
  filterByYear = (year) => {
    this.setState({ selectedYear: year });
  };

  render() {
    const { selectedYear, selectedImage, isLightboxOpen, images } = this.state;

    // Get unique combinations of name and year from images
    const years = [...new Set(images.map((img) => `${img.name}-${img.year}`))];

    // Filter images based on selected name and year
    const filteredImages = selectedYear
      ? images.filter((img) => `${img.name}-${img.year}` === selectedYear)
      : images;

    return (
      <div className='container'>
        <div className="gallery-container">
          {/* Sidebar with year filter */}
        

          {/* Gallery grid */}
          <div className="gallery-grid">
  {filteredImages.map((image) => (
    <div key={image.id} className="gallery-item" onClick={() => this.openLightbox(image)}>
      <img src={image.src} alt={image.title} className="galleyimg-fluid" />
    </div>
  ))}
</div>



          {/* Lightbox */}
          {isLightboxOpen && selectedImage && (
            <div className="lightbox" onClick={this.closeLightbox}>
              <div className="lightbox-content">
                <span className="close" onClick={this.closeLightbox}>&times;</span>
                <img src={selectedImage.src} alt={selectedImage.title} />
                <p>{selectedImage.title}</p>
              </div>
            </div>
          )}
            <div className="sidebar bg-white ">
            <h5>Abhinayam with Vesham</h5>
            <ul>
              <li onClick={() => this.filterByYear(null)}>All Years</li>
              {years.map((year) => (
                <li key={year} onClick={() => this.filterByYear(year)}>
                  {year}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
