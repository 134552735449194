import React from 'react';
import DataTable from 'react-data-table-component';
export default class GetActivitiesTable extends React.Component {
  constructor() {
    super();
    this.state = {ActivitiesList:[]};
  }
  getActivitiesList=()=>{
    fetch('https://trust.sharavisual.com/api/getActivities.php')
    .then(response => { 
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.status) {
        this.setState({ ActivitiesList: data.data });
        console.log('Fetched data:', data);
      } else {
        console.error('Error from API:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
   
    } 
   
    componentDidMount() {
      
      this.getActivitiesList();
      
    }
  render() {
    const columns = [
        {
          name: 'Activite Name',
          selector: (row) => row.activitieName,
          sortable: true,
        },
        {
          name: 'Date',
          selector: (row) => row.date,
          sortable: true,
        },
        {
          name: 'Time',
          selector: (row) => row.time,
          sortable: true,
        },
        {
          name: 'Place',
          selector: (row) => row.place,
          sortable: true,
        }
        
      ];
    return (
         <>
         <DataTable
          columns={columns}
          data={this.state.ActivitiesList}
          pagination
        />
         </>
    );
  }
}