import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../assets/admin/css/login.css';
import '../../assets/admin/css/admin.scss';
class Sidebar extends React.Component {
  constructor() {
    super();
    this.state = {login:""};
  }
  render() {
    return (
         <>
     <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">         
          <li class="nav-item">
          <a class={this.props.location.pathname === '/GetInvolvedList' ? 'nav-link active' : 'nav-link'} href="/GetInvolvedList">            
              <span class="menu-title">Get Involved List</span>
            </a>
          </li>
          <li class="nav-item">
          <a class={this.props.location.pathname === '/DonationList' ? 'nav-link active' : 'nav-link'} href="/DonationList">            
              <span class="menu-title">Donation List</span>
            </a>
          </li>         
          <li class="nav-item">
          <a class={this.props.location.pathname === '/AddActivitie' ? 'nav-link active' : 'nav-link'} href="/AddActivitie">            
              <span class="menu-title">Crearte Activitie </span>
            </a>
          </li>
          <li class="nav-item">
          <a class={this.props.location.pathname === '/ActivitieList' ? 'nav-link active' : 'nav-link'} href="/ActivitieList">            
              <span class="menu-title">Activities List</span>
            </a>
          </li>
          <li class="nav-item">
                       
              <span class="menu-title nav-link" onClick={() => {
    localStorage.removeItem('isLoggedIn');
    window.location.href = '/';
  }}
>Logout</span>
            
          </li>
          
        </ul>
      </nav>
         </>
    );
  }
}
export default withRouter(Sidebar);