import React from 'react';
import logo from '../assets/img/logo.png';
import { withRouter } from 'react-router-dom';

 class MenuSection extends React.Component {
  constructor() {
    super();
    this.state = {headerMenu:[],
      isplaying:false};
    this.audioRef = React.createRef();

  }
  isActive=(path)=> {
    
    return this.props.location.pathname === path ? 'nav-item nav-link active' : 'nav-item nav-link';
}
getHeaderMenu=()=>{
  fetch('https://trust.sharavisual.com/data/headerMenu.json')
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      this.setState({headerMenu:data})
      console.log('Fetched data:', data);
      // You can now use the data in your component state
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    }); 
}
togglePlay=()=>{
  const {isplaying} = this.state;
  //const audio = this.audioRef.current;
  let audio = document.getElementById("audio");
  if(isplaying){
    audio.pause();
  }else{
    audio.play();
  }
  this.setState({isplaying:!isplaying})
}

componentDidMount(){
  this.getHeaderMenu()
}
  render() {
    return (
        <> 
      <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-2 py-lg-0">
      <a href="/" class="navbar-brand d-flex align-items-center">
          <img src={logo} style={{width:"150px"}}/>
          <h5 class="m-0"> Sree Ramakrishna <br/>Bhajana Sabha Trust (Regd.) <br/>
          <small className="fs-7 text-primary me-2">Uplifting Spirits, Serving Society.</small>
        </h5>
        
        </a>
       
        <button
        type="button"
        class="navbar-toggler"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto py-3 py-lg-0">
            {this.state.headerMenu.length>0 && this.state.headerMenu.map((item,index)=>{
              
              return(
                <>
                <a href={item.link} className={this.isActive(item.activeName)}>{item.name}</a>
                        

                </>
                
              )
            })}
            <a href='#' onClick={this.togglePlay} className='nav-item nav-link'> <i class={this.state.isplaying?"fa fa-volume-up":"fa fa-volume-mute"}></i></a>
             <audio id="audio" src="https://trust.sharavisual.com/assets/mahamantram.mp3"></audio>
            
            {/* <audio ref={this.audioRef}>
           
            <source src="https://trust.sharavisual.com/assets/home/audio.mp3" type='audio/mpeg'></source>
            </audio> */}
        </div>
      </div>
    </nav>

        </>
    );
  }
}
export default withRouter(MenuSection);
